<template>
    <v-container class="fade">
        <v-row class="primary-font mb-10 mt-3">
            <v-col cols="12" md="6" class="py-3 f14 d-flex flex-column justify-center">
                <span class="f20 fw600">The Vision is the Challenge</span>
                <v-divider class="l-primary my-5" width="70%" />
                <p v-for="(paragraph, i) in paragraphs" :key="i">
                    {{ paragraph }}
                </p>              
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                <v-img :src="require('@/assets/images/landing/csi-vision.png')" contain/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'TheVision',
    data: () => ({
        paragraphs: [
            'The sheer size and scope of the civil service is a daunting and challenging task. A total of 1,409,660 employees distributed by categories of service/major subdivisions are at 913,087 for National Government Agencies (NGA), 66,222 for Government Owned and Controlled Corporations (GOCC), 365,725 for Local Government Units (LGU), 51,051 for State Universities and Colleges, and 13,575 for Local Water Districts.',
            'To achieve the CSC’s strategic objective of developing high performing and ethical civil servants and its vision to be Asia’s leading center of excellence for strategic HR & OD, CSI must focus on select priorities for capability building. It also recognizes that most government agencies already have the inherent and internal capacities to fulﬁll the training and development requirements of their people. As such, harmonizing and unifying the efforts of all stakeholders will beneﬁt the bureaucracy tremendously.',
            'CSI will complement rather than compete in the task of equipping civil servants with the right skills and competency set through training and development. CSI also knows that a vibrant development agenda for public service will open opportunities for and/or renew interest in public sector research, and create even deeper and productive partnerships with the HR industry in general.',
        ]
    })
}
</script>
